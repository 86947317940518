import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useUserStore from "../../../services/userStore";
import axios from "axios";
import { NGROK } from "../../../APIs";
import { myLocalStorage } from "../../../components/StorageHelper";
import moment from "moment";
import MyHeaderTableCell from "../Components/MyHeaderTableCell";
import SyncIcon from "@mui/icons-material/Sync";
import PersonIcon from "@mui/icons-material/Person";
import DevicesIcon from "@mui/icons-material/Devices";
import GroupIcon from "@mui/icons-material/Group";
import AzureDeviceItem from "./Components/AzureDeviceItem";
import AzureGroupDetails from "./Components/AzureGroupDetails";
import AzureUserDetails from "./Components/AzureUserDetails";

const AzureUsers = () => {
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const userData = useUserStore((state) => state.user);
  const [tenantList, setTenantList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [azureUsers, setAzureUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [azureDevices, setAzureDevices] = useState([]);
  const [azureUserGroups, setAzureUserGroups] = useState([]);
  const [azureDevicesLoading, setAzureDevicesLoading] = useState(false);
  const [azureUserGroupsLoading, setAzureUserGroupsLoading] = useState(false);
  const [searchUsers, setSearchUser] = useState("");
  const [searchGroups, setSearchGroups] = useState("");
  const [searchDevices, setSearchDevices] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const typographyHeaderStyles = {
    fontSize: "16px",
    display: "inline",
    fontWeight: 600,
  };

  const typographyBodyStyles = { fontSize: "16px", display: "inline" };

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
  };
  const handleGroupClick = (group) => {
    setSelectedGroup(group);
    setSelectedDevice(null);
  };
  const handleDeviceClick = (device) => {
    setSelectedDevice(device);
    setSelectedGroup(null);
  };
  const handleUserRowClick = (users) => {
    setSelectedUsers(users);
    getUserDevice(users);
    getUserGroups(users);
    setSelectedGroup(null);
    setSelectedDevice(null);
  };

  const demandSync = async () => {
    const response = await axios.get(`${NGROK}/api/azure-sync/onDemand`, {
      params: {
        email: userData.email,
      },
    });
  };

  const getUserGroups = async (users) => {
    setAzureUserGroupsLoading(true);
    const response = await axios.get(
      `${NGROK}/api/azure/v1/users-group?userId=${users?.id}`,
    );
    if (response?.data) {
      setAzureUserGroups(response.data);
    }
    setAzureUserGroupsLoading(false);
  };

  const getUserDevice = async (users) => {
    setAzureDevicesLoading(true);
    const response = await axios.get(
      `${NGROK}/api/azure/v1/user-devices?userId=${users?.id}`,
    );
    if (response?.data) {
      setAzureDevices(response.data);
    }
    setAzureDevicesLoading(false);
  };

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  const fetchAzureUsers = async (isFirst = false) => {
    if (isFirst) {
      setLoading(true);
    }
    const response = await axios.get(
      `${NGROK}/api/azure/v1/users?email=${userData.email}`,
    );
    if (response?.data) {
      setAzureUsers(response?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
    fetchAzureUsers(true);
    const interval = setInterval(() => {
      fetchAzureUsers();
    }, 5000);
    return () => clearInterval(interval);
  }, [userData]);

  const filteredUsers = azureUsers?.filter((user) =>
    user.displayName.toLowerCase().includes(searchUsers.toLowerCase()),
  );

  const filteredGroups = azureUserGroups?.filter((group) =>
    group?.displayName.toLowerCase().includes(searchGroups.toLowerCase()),
  );

  const filteredDevices = azureDevices?.filter((device) =>
    device.displayName.toLowerCase().includes(searchDevices.toLowerCase()),
  );

  return (
    <Box p={2}>
      <Stack direction="column" spacing={5} mb={3}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontSize: "22px",
          }}
        >
          Azure Users
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            maxWidth: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {tenantList?.length > 0 && (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={selectedTenantName || ""}
                options={tenantList.map((tenant) => tenant.tenantName)}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Tenant List" />
                )}
                onChange={(e, value) => {
                  handleTenantChange(value);
                }}
              />
            )}
          </Box>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
            onClick={() => {
              demandSync();
            }}
          >
            <SyncIcon />
            Sync
          </Button>
        </Box>

        <Stack direction="row" spacing={2}>
          <Box width="30%">
            <TableContainer component={Paper}>
              <Table
                sx={{
                  width: "100%",
                  height: "fit-content",
                  "& td, & th": {
                    border: "1px solid #233044",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <MyHeaderTableCell
                      sx={{ fontSize: 18 }}
                      align={"center"}
                      className={"userName"}
                    >
                      <Stack
                        alignItems={"center"}
                        spacing={2}
                        direction={"row"}
                        justifyContent={"center"}
                      >
                        <PersonIcon sx={{ marginRight: 2 }} />
                        <Typography variant="h3" fontSize={18}>
                          Users
                        </Typography>
                      </Stack>
                    </MyHeaderTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2} align="right">
                      <TextField
                        variant="outlined"
                        placeholder="Search users..."
                        value={searchUsers}
                        onChange={(e) => setSearchUser(e.target.value)}
                        sx={{ width: "100%" }}
                      />
                    </TableCell>
                  </TableRow>
                  {loading ? (
                    <CircularProgress />
                  ) : filteredUsers?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "18px",
                          }}
                        >
                          No Users Found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredUsers?.map((user, index) => (
                      <TableRow
                        selected={user?.id === selectedUsers?.id}
                        key={index}
                        onClick={() => handleUserRowClick(user)}
                        style={{ cursor: "pointer" }}
                        component="td"
                        scope="row"
                        className="groupRowName userName"
                        sx={{
                          "&:hover": {
                            background: "#f5f5f5",
                            cursor: "pointer",
                          },
                          "& td, & th": {
                            border: "1px solid #233044",
                            fontSize: "16px",
                          },
                          "&.Mui-selected": {
                            background: "#233044 !important",
                            "& td, & th": {
                              color: "#fff",
                            },
                          },
                        }}
                      >
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <PersonIcon sx={{ marginRight: 2 }} />
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "16px" }}
                            >
                              {user?.displayName}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box width="30%">
            {selectedUsers && (
              <Box
                sx={{
                  border: "1px solid",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    background: "#233044",
                    color: "#fff",
                    padding: "10px",
                    borderRadius: "5px",
                    fontSize: "18px",
                    p: 4.8,
                  }}
                >
                  Details
                </Typography>

                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  aria-label="Groups and Users Tabs"
                  sx={{ fontSize: 16 }}
                >
                  <Tab
                    label="User"
                    icon={<PersonIcon />}
                    iconPosition="start"
                    sx={{ fontSize: 16 }}
                  />
                  <Tab
                    label="Devices"
                    icon={<DevicesIcon />}
                    iconPosition="start"
                    sx={{ fontSize: 16 }}
                  />
                  <Tab
                    label="Groups"
                    icon={<GroupIcon />}
                    iconPosition="start"
                    sx={{ fontSize: 16 }}
                  />
                </Tabs>
                {selectedTab === 0 && (
                  <>
                    {selectedUsers ? (
                      <AzureUserDetails user={selectedUsers} />
                    ) : null}
                  </>
                )}
                {selectedTab === 1 && (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{
                          height: "fit-content",
                          "& th": {
                            background: "#233044",
                            color: "#fff",
                          },
                          "& td, & th": {
                            fontSize: "16px",
                          },
                        }}
                        size="large"
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={2} align="right">
                              <TextField
                                variant="outlined"
                                placeholder="Search devices..."
                                value={searchDevices}
                                onChange={(e) =>
                                  setSearchDevices(e.target.value)
                                }
                                sx={{ width: "100%" }}
                              />
                            </TableCell>
                          </TableRow>
                          {azureDevicesLoading ? (
                            <CircularProgress />
                          ) : filteredDevices.length === 0 ? (
                            <TableRow>
                              <TableCell colSpan={5} align="center">
                                <Typography variant="body1">
                                  No device found
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ) : (
                            filteredDevices.map((device, index) => (
                              <TableRow
                                key={index}
                                selected={device?.id === selectedGroup?.id}
                                onClick={() => handleDeviceClick(device)}
                                style={{ cursor: "pointer" }}
                                component="td"
                                scope="row"
                                className="groupRowName userName"
                                sx={{
                                  "&:hover": {
                                    background: "#f5f5f5",
                                    cursor: "pointer",
                                  },
                                  "& td, & th": {
                                    border: "1px solid #233044",
                                    fontSize: "16px",
                                  },
                                  "&.Mui-selected": {
                                    background: "#233044 !important",
                                    "& td, & th": {
                                      color: "#fff",
                                    },
                                  },
                                }}
                              >
                                <TableCell>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <DevicesIcon sx={{ marginRight: 2 }} />
                                    <Typography
                                      variant="body1"
                                      sx={{ fontSize: "16px" }}
                                    >
                                      {device.displayName}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
                {selectedTab === 2 && (
                  <TableContainer component={Paper}>
                    <Table
                      sx={{
                        height: "fit-content",
                        "& th": {
                          background: "#233044",
                          color: "#fff",
                        },
                        "& td, & th": {
                          fontSize: "16px",
                        },
                      }}
                      size="large"
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={2} align="right">
                            <TextField
                              variant="outlined"
                              placeholder="Search groups..."
                              value={searchGroups}
                              onChange={(e) => setSearchGroups(e.target.value)}
                              sx={{ width: "100%" }}
                            />
                          </TableCell>
                        </TableRow>
                        {azureUserGroupsLoading ? (
                          <CircularProgress />
                        ) : filteredGroups.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={7}>
                              <Typography variant="body1" align="center">
                                No Groups available
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          filteredGroups.map((group, index) => (
                            <TableRow
                              key={index}
                              selected={group?.id === selectedGroup?.id}
                              onClick={() => handleGroupClick(group)}
                              style={{ cursor: "pointer" }}
                              component="td"
                              scope="row"
                              className="groupRowName userName"
                              sx={{
                                "&:hover": {
                                  background: "#f5f5f5",
                                  cursor: "pointer",
                                },
                                "& td, & th": {
                                  fontSize: "16px",
                                },
                                "&.Mui-selected": {
                                  background: "#233044 !important",
                                  "& td, & th": {
                                    color: "#fff",
                                  },
                                },
                              }}
                            >
                              <TableCell>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <GroupIcon sx={{ marginRight: 2 }} />
                                  <Typography
                                    variant="body1"
                                    sx={{ fontSize: "16px" }}
                                  >
                                    {group.displayName}
                                  </Typography>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            )}
          </Box>
          <Box width="30%">
            {selectedGroup ? (
              <>
                <Box
                  sx={{
                    border: "1px solid",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      background: "#233044",
                      color: "#fff",
                      padding: "10px",
                      borderRadius: "5px",
                      fontSize: "18px",
                      p: 4.8,
                    }}
                  >
                    Details of Groups
                  </Typography>

                  <Box>
                    {selectedGroup ? (
                      <AzureGroupDetails group={selectedGroup} />
                    ) : (
                      <Typography sx={{ fontSize: 16 }}>
                        No details found
                      </Typography>
                    )}
                  </Box>
                </Box>
              </>
            ) : selectedDevice ? (
              <Box
                sx={{
                  border: "1px solid",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    background: "#233044",
                    color: "#fff",
                    padding: "10px",
                    borderRadius: "5px",
                    fontSize: "18px",
                    p: 4.8,
                  }}
                >
                  Details of Groups
                </Typography>
                {selectedDevice ? (
                  <>
                    <AzureDeviceItem device={selectedDevice} />
                  </>
                ) : (
                  <Typography sx={{ fontSize: 16 }}>
                    No details found
                  </Typography>
                )}
              </Box>
            ) : null}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AzureUsers;
